import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Welcome" />
    <header className="bg-azure">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 pb-8 md:p-0">
            <h1 className="text-4xl text-center py-4">
              District 28 Alcoholics Anonymous Online
            </h1>
            <p className="text-xl py-4">
              Welcome to our new website! This is the home of District 28 AA. An online resource for scheduling AA meetings and linking to 12-step information as well as events in our area.
            </p>
            <StaticImage objectFit="contain" height={600} src="../images/michigan_map.jpg" alt="AA Service District/Area Map" />
            <p className="text-xl text-center italic py-2">
              District 28 covers part of northeastern Michigan. We service Alpena, Montmorency and Presque Isle County
            </p>
          </div>
          <div className="w-full md:w-1/2 pb-8 md:p-0">
            <StaticImage className="mt-12" objectFit="scale-down" src="../images/main.png" alt="AA Service Logo" />
            <div className="bg-white rounded-lg shadow p-8 mx-6">
              <h2 className="text-2xl italic text-blue-darkest leading-normal break-words">
                “It must never be forgotten that the purpose of Alcoholics Anonymous is to sober up alcoholics. There is no religious or spiritual requirement for membership. No demands are made on anyone. An experience is offered which members may accept or reject. That is up to them.”
              </h2>
              <p className="text-xl text-right pt-4 text-gray-600">
                - Bill W.
              </p>
            </div>
            <div className="py-8">
              <div className="bg-white rounded-lg shadow p-8 mx-6">
                <h2 className="text-2xl italic text-blue-darkest leading-normal break-words">
                  “When anyone, anywhere, reaches out for help I want the hand of AA to be there. And for that I am responsible.”
                </h2>
                <p className="text-xl text-right pt-4 text-gray-600">
                  - Alcoholics Anonymous pg. ??
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Layout>
)

export default IndexPage
